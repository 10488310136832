import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MqttMessagesDataService } from './mqtt-messages-data.service';
import { MqttMessage, MqttMessageSignal } from '../models/MqttMessage';

@Injectable({
  providedIn: 'root',
})
export class MqttMessagesService {
  private apiUrl = environment.mqttMessagesApi;

  constructor(
    private http: HttpClient,
    private mqttMessagesDataService: MqttMessagesDataService,
  ) {}

  updateMessagesFromDb(identifier: string) {
    this.mqttMessagesDataService.createMessage(identifier);

    this.http.get<any>(`${this.apiUrl}/read/${identifier}`).subscribe({
      next: this.handleSuccess.bind(this, identifier),
      error: this.handleError.bind(this),
      complete: this.handleComplete.bind(this),
    });
  }

  private handleSuccess(uid: string, message: MqttMessage) {
    this.mqttMessagesDataService.setMessages(uid, message);
    this.mqttMessagesDataService.updateInfo(uid, message);
    this.mqttMessagesDataService.updateWarn(uid, message);
    this.mqttMessagesDataService.updateAlarm(uid, message);
  }

  private handleError(error: HttpErrorResponse) {
    this.mqttMessagesDataService.updateError(error);
  }

  private handleComplete() {}

  refresh(identifier: string) {
    this.http.get<any>(`${this.apiUrl}/refresh/${identifier}`).subscribe(
      (data) => {
        // console.log(data);
      },
      (error) => {
        // console.log(error);
      },
      () => {
        // console.log('complete');
      },
    );
  }

  archive(id: string, start: Date, end: Date) {
    const payload = {
      mqtt: id,
      start: start,
      end: end,
    };

    return this.http.post<MqttMessageSignal[]>(
      `${this.apiUrl}/archive`,
      payload,
    );
  }
}
